.chart-container {
    width: 90%;
    margin: 0 auto;
}

.canvas {
    display: block;
    width: 100% !important;
    height: auto !important;
}
