.invest_section
{
    width: 100vw;
    display: grid;
    align-items: center;
    grid-template-columns: 45% 55%;
    text-align: end;
}

.invest_title
{
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
}

.invest_animation
{
    grid-column-start: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.invest_section2
{
    width: 100vw;
    display: grid;
    align-items: center;
    grid-template-columns: 55% 45%;
    text-align: start;
}

.invest_title2
{
    grid-column-start: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    justify-content: center;
}

.invest_animation2
{
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


h4
{
    font-size: 7.2rem;
    margin: 0;
}

.blanks
    {
        display: block;
    }


/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {


    .invest_section
    {
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-rows: 45% 55%;
        grid-template-columns: 100%;
        text-align: center;

    }

    .invest_title
    {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .invest_animation
    {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    


    .invest_section2
    {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 100%;
        grid-template-rows: 45% 55%;

    }

    .invest_title2
    {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }

    .invest_animation2
    {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    h4
    {
        font-size: 5.2rem;
        margin: 0;
    }

    .blanks
    {
        display: none;
    }
        

}


