.container {  display: grid;
    width: 100vw;
    height: 145vh;
    grid-template-columns: 5% 30% 30% 30% 5%;
    grid-template-rows: 35% 45% 20%;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      ". My-Money My-Money Profits ."
      ". Daily-Profits LeaderBoard LeaderBoard ."
      ". referal referal referal .";
  }
  
  .My-Money 
  { 
    grid-area: My-Money;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .userdata
  {
    width: 100%;
    height: 92%;
    display: grid;
    grid-template-columns: 5% 30% 30% 30% 5%;
    border-radius: 2.2rem;
    background-color: #2b2d31;
  }

  .dashboard_card_1
  {
    grid-column-start: 2;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  .dashboard_card_2
  {
    grid-column-start: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .dashboard_card_3
  {
    grid-column-start: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .Profits 
  { 
    grid-area: Profits; 
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .Daily-Profits 
  { 
    grid-area: Daily-Profits; 
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 2.2rem;
    background-color: #2b2d31;
  }
  
  .LeaderBoard 
  { 
    grid-area: LeaderBoard; 
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    margin-left: 4.1rem;
    justify-content: center;
    flex-direction: column;
  }
  
  .referal 
  { 
    grid-area: referal; 
    width: 100%;
    height: 100%;
  }

  .Referral_Program_box
    {
    border: solid 1px ;
    padding: 2.1%;
    border-radius: 1.2rem;
    height: auto;
    display: flex;
    text-align: start;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    }
  