.signUp_Section
{
    min-width: 100vw;
    height: 100vh;
    height: 98vh;
    display: grid;
    grid-template-columns: 55% 45%;
}

.signUp_animation
{
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.signUp_container
{
    grid-column-start: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.signUp_form
{
    width: 62%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.signUp_input
{
    width: 100%;
    background-color: transparent;
    margin-top: 2.1%;
    margin-bottom: 2.1%;
}

.signUp_btn
{
    width: 100%;
}


/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    
    .signUp_Section
    {
        width: 100%;
        margin-top: 2.1%;
        margin-bottom: 2.1%;
        height: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 30% 70%;
        text-align: center;
    }

    .signUp_animation
    {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .signUp_container
    {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .signUp_form
    {
        width: 80%;
    }
    

}