.profile_section
{
    width: 100vw;
    height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile_form_title
{
    width: 65%;
    height: 92%;
    text-align: center;
}

.profile_form
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-group
{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-input
{
    background-color: transparent;
    padding-left: 1.2rem;
    width: 55%;
}

select
{
    width: 45%;
    text-align: start;
    padding-top: 0.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;  /* Added padding-right */
    display: block;
    padding-bottom: 0.8rem;
    background-color: transparent;
    color: white;
    border: solid 2px #e4e4e7;
    outline: none;
    border-radius: 0.6rem;
    margin-top: 2.1%;
    appearance: none; /* Hides the default arrow */
}
/* Styles for select options */
select option {
    background-color: #0b0b15; /* Change background color of options to purple */
    color: white; /* Change text color of options to white */
}

.submit_button
{
    margin-top: 2.1%;
    width: 45%;
}


/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    

    .profile_section
    {
        width: 100%;
        height: 100%;
    }


    .profile_form_title
    {
        width: 95%;
        text-align: center;
    }

    .profile-input
    {
        background-color: transparent;
        width: 95%;
    }

    select
    {
        width: 95%;
        margin-top: 4.1%;
    }

    .profile_form
    {
        width: 95%;
        text-align: center;
    }

}
