.header_section
{
    width: 100vw;
    display: grid;
    margin: 0;
    height: auto;
    padding-bottom: 1.2%;
    background-color: transparent;
    grid-template-columns: 5% 10% 45% 10% 20% 5%;
}

.Header_logo
{
    grid-column-start: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header_buttons
{
    grid-column-start: 3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.header_login
{
    grid-column-start: 5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}



.header_button
{
    background-color: transparent;
    border: none;
    transition: background-color 0.5s, border-radius 0.5s;
}

.header_button:hover
{
    background-color: #2b2d31;
    border-radius: 0.6rem;
    border: none;
}

.header_login_button
{
    color: white;
    /* Changed to inline-block for proper sizing */
    border: 1px solid;
    border-color: #6C63FF;
    background-color: transparent;
    font-size: medium;
    border-radius: 0.6rem;
    cursor: pointer;
    transition: color .2s ease-in;
    transition: background-color 0.5s,
}

.header_login_button:hover
{
    /* Changed to inline-block for proper sizing */
    background-color: #6C63FF;
}


/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    .header_section
    {
        width: 100vw;
        display: grid;
        margin: 0;
        height: auto;
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        background-color: transparent;
        grid-template-columns: 5% 25% 65% 5;
    }

    .header_buttons
    {
        display: none;
    }

    .header_login
    {
       display: none;
    }

    .Header_logo
    {
        grid-column-start: 2;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
}