.containerStyle2
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 62%;
}

/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    
    .containerStyle2
    {
        width: 62%;
    }

    
}