
.privacy_section
{
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.privacy
{
    width: 76vw;
}

/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    
    .privacy_section
    {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }


    .privacy
    {
        width: 82%;
    }

}