.hero_1
{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 0;
}

.heading
{
    margin-bottom: 0.1%;
}


.hero_2
{
    width: 100vw;
    display: grid;
    grid-template-columns: 5% 35% 55% 5%;
    margin: 0;
}

.hero_3
{
    width: 100vw;
    height: auto;
    display: grid;
    grid-template-columns: 45% 55%;
}


.hero_4_animations
{
    width: 100%;
    height: 100%;
    grid-column-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.hero_4_depoist
{
    width: 100%;
    height: 100%;
    grid-column-start: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}


.hero_2_animation
{
    width: 100%;
    height: 100%;
    grid-column-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

}

.hero_2_titles
{
    width: 100%;
    grid-column-start: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}


.hero_4
{
    width: 100vw;
    height: 40vh;
    display: grid;
    text-align: center;
    grid-template-columns: 5% 1fr 3% 1fr 3% 1fr 3% 1fr 5%;
    margin-top: 4%;
    margin-bottom: 4%;
}

.hero_4_card_1
{
    grid-column-start: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 1.2rem;
    background-color: #2b2d31;
}

.hero_4_card_2
{
    grid-column-start: 4;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1.2rem;
    background-color: #2b2d31;
}

.hero_4_card_3
{
    grid-column-start: 6;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1.2rem;
    background-color: #2b2d31;
}

.hero_4_card_4
{
    grid-column-start: 8;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1.2rem;
    background-color: #2b2d31;
}










/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    

    .hero_1
    {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin: 0;
    }

    .hero_2
    {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 40% 60%;
        margin: 0;
    }


    .hero_2_animation
    {
        width: 100%;
        height: 100%;
        grid-column-start: 1;
        grid-row-start: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

    }

    .hero_2_titles
    {
        width: 100%;
        grid-column-start: 1;
        grid-row-start: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    p
    {
        padding-left: 1.2%;
        padding-right: 1.2%;
    }



    .hero_3
    {
        width: 100vw;
        height: auto;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 45% auto;
    }


    .hero_4_animations
    {
        width: 100%;
        height: 100%;
        grid-column-start: 1;
        grid-row-start: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .hero_4_depoist
    {
        width: 100%;
        height: 100%;
        grid-column-start: 1;
        grid-row-start: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }


    .hero_4
    {
        display: none;
    }
}