.otp_section
{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 45% 10% 35% 10%;
}

.otp_animation
{
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.otp_Input
{
    grid-column-start: 3;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {

    .otp_section
    {
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 45% 55%;
    }

    .otp_animation
    {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .otp_Input
    {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
    }
        

}