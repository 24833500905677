.login_section
{
    width: 100vw;
    height: 98vh;
    display: grid;
    grid-template-columns: 55% 45%;
}

.login_animation
{
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_container
{
    grid-column-start: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.login_form
{
    width: 62%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.login_input
{
    width: 100%;
    background-color: transparent;
    margin-top: 2.1%;
    margin-bottom: 2.1%;
}

.login_btn
{
    width: 100%;
}


/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    


    .login_section
    {
        width: 100vw;
        margin-top: 2.1%;
        margin-bottom: 2.1%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 30% 70%;
        text-align: center;
    }

    .login_animation
    {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login_container
    {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login_form
    {
        width: 80%;
    }
    

}