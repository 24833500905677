.checkout_section
{
    width: 100vw;
    height: auto;
    padding-top: 6%;
    padding-bottom: 6%;
    display: grid;
    grid-template-columns: 45% 55%;
}

.checkout_animation
{
    grid-column-start: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.checkout_form_section
{
    grid-column-start: 2;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.checkout_form
{
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.checkout-input
{
    width: 100%;
    height: auto;
    background-color: transparent;
    margin-top: 1.1%;
    margin-bottom: 1.1%;
}
form
{
    width: 80%;
}

.checkout-select
{
    width: 100%;
    background-color: transparent;
    margin-top: 1.1%;
    margin-bottom: 1.1%;
}


/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {

    .checkout_section
    {
        width: 100vw;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 25% 75%;
    }


    .checkout_animation
    {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .checkout_form_section
    {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .checkout_form
    {
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    form
    {
        width: 80%;
        text-align: center;
    }

        

}

.qr_code
{
    width: 42%;
}