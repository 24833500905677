.affiliate_section
{
    width: 100vw;
    height: auto;
    display: grid;
    text-align: center;
    grid-template-columns: 100%;
    grid-template-rows: auto 50vh auto;
}

.titles_1
{
    width: 100%;
    display: flex;
    grid-row-start: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4%;
    margin-bottom: 4%;
}

.titles_2
{
    width: 100%;
    display: flex;
    grid-row-start: 3;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4%;
    margin-bottom: 4%;
}

.context
{
    height: 100%;
    width: 100vw;
    display: grid;
    grid-row-start: 2;
    align-items: center;
    grid-template-columns: 3% 30% 2% 30% 2% 30% 3%;
}

.affiliate_card_1
{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    grid-column-start: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2b2d31;
}

.affiliate_card_2
{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    grid-column-start: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2b2d31;
}

.affiliate_card_3
{
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    grid-column-start: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2b2d31;
}


@media only screen and (max-width: 768px) {
    
    .affiliate_section
    {
        width: 100vw;
        height: auto;
        display: grid;
        text-align: center;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }

    .titles_1
    {
        width: 100%;
        display: flex;
        grid-row-start: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 4%;
        margin-bottom: 4%;
    }

    .titles_2
    {
        width: 100%;
        display: flex;
        grid-row-start: 2;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 4%;
        margin-bottom: 4%;
    }

    .context
    {
        display: none;
    }

   


}