.compound_trading_section
{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 55% 45%;
}

.compoundTrading_Animation_Section
{
    
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    grid-column-start: 1; 
}

.compoundTrading_Calculator_Section
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    grid-column-start: 2;

}

  /* Styles for mobile and tablet devices */
  @media only screen and (max-width: 768px) {
    
    .compound_trading_section
    {
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
    }

    .compoundTrading_Animation_Section
    {
        
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        text-align: center;
        grid-column-start: 1;
        grid-row-start: 1; 
    }

    .compoundTrading_Calculator_Section
    {
        display: none;
    }

}

