#amountDisplay, #monthsDisplay {
    display: block;
    font-size: large;
    font-weight: normal;
    margin-bottom: 2.1%;
  }
  
  #amount, #months {
    width: 450px;
  }
  
  /* Style for the range slider */
  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    outline: none;
    border-color: transparent;
    background-color: transparent;
    outline: none;
  }
  
  /* Style for the thumb */
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #6C63FF;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -5px;
  }
  
  /* Style for the range before the thumb */
  input[type=range]::-webkit-slider-runnable-track {
    background: #e4e4e4;
    height: 10px;
    border-radius: 5px;
  }
  
  /* Growth Section */
  .Growth-Calculator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .Growth-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flex_center_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flex_dir_col {
    flex-direction: column;
  }
  
  .flex_dir_row {
    flex-direction: row;
  }
  
  .highlight {
    color: #6C63FF;
  }
  

  .slider_layout
  {
    text-align: start;
  }



