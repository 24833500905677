.ticker {
    display: flex;
    width: 100vw;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
  }
  
  .ticker-item {
    margin-left: 1.1%;
}

/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    .ticker {
        display: none;
      }
      
      .ticker-item {
       display: none;
    }
     
}