.footer
{
    width: 100vw;
    height: auto;
    display: grid;
    padding-top: 0.4%;
    padding-bottom: 0.4%;
    align-items: center;
    justify-content: center;
    grid-template-columns: 5% 25% 20% 40% 5%;
}

.copyRight_section
{
    grid-column-start: 2;
    width: 100%;
}


.footer_links_section {
    grid-column-start: 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.links
{
    margin-top: 1.1%;
    margin-bottom: 1.1%;
    text-decoration: none;
    color: white;
    font-size: medium;
}

.links:hover
{
    color: #6C63FF;
}


/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    .footer_links_section{
        display: none;
    }

    .footer
    {
        width: 100vw;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-template-columns: 100%;
        margin-top: 2.1%;
        margin-bottom: 2.1%;
    }

    .copyRight_section
    {
        grid-column-start: 1;
        width: 100%;
        text-align: center;
    }

}