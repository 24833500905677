/* Snackbar.css */

#snackbar {
    visibility: hidden;
    min-width: 250px;
    max-width: 450px;
    margin-left: -125px;
    background-color: #6C63FF;
    color: #FFF;
    font-size: 18px;
    outline: none;
    text-overflow: ellipsis;
    text-align: center;
    border-radius: 12px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-family: 'Roboto', sans-serif;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.9s, fadeout 0.9s 2.5s;
    animation: fadein 0.9s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}
