@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

html,
body {
    inset: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #0b0b15;
    display: flex;
    font-family: "Sora", sans-serif;
    font-optical-sizing: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: white;
    cursor: default;
}

::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.highlight
{
    color: #6C63FF;
}

ul 
{
    list-style-type: none;
}

.card_background
{
    background-color: #2b2d31;
}

.outlines {
    border: solid 1px #e4e4e7;
}

button {
    padding: 1rem 2.8rem;
    /* Adjusted padding values */
    background-color: #6C63FF;
    color: white;
    display: inline-block;
    /* Changed to inline-block for proper sizing */
    border: 1px solid;
    border-color: #6C63FF;
    font-size: medium;
    border-radius: 0.6rem;
    cursor: pointer;
    transition: color .2s ease-in;
    /* Added padding transition */
}
.text-center
{
    text-align: center;
}

/* Styles for mobile and tablet devices */
@media only screen and (max-width: 768px) {
    
    h1
    {
        padding-left: 1.2%;
        padding-right: 1.2%;
    }
    
    p
    {
        padding-left: 1.2%;
        padding-right: 1.2%;
    }
}